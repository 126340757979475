import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { invoiceAPI } from '../../API/Invoice';
import { allImage } from '../../Utils/allImageImport';
import { dateFormatTable } from '../../Utils/constants';
import DataTableControl from '../Common/DataTable';
import Toaster from '../Common/Toaster';

export default function InvoiceComponent() {
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  useEffect(() => {
    setFilter({});
  }, []);

  const getTableData = async (filter) => {
    setLoading(true);
    filter.$page = filter.$page || 1;
    filter.$limit = filter.$limit || 10;
    const res = await invoiceAPI?.getAllInvoices();
    if (res || !res) setLoading(false);
    if (res && res?.status === true) {
      toaster && setToaster(false);
      let list = res?.data?.docs;
      list = list?.map((lists) => {
        lists.endDate = lists?.dates;
        return lists;
      });
      res.data.docs = list;
      return res?.data;
    } else {
      setToaster(true);
      setError({
        ...error,
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      return [];
    }
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const handleOpenLink = (url) => {
    if (url) {
      window?.open(url, '_black');
    }
  };

  const headingData = [
    {
      key: 'invoice',
      sortable: false,
      header: ' ',
      render: (value) => {
        return (
          <div className="applicants-action">
            <span>
              <img
                className="cursor-pointer"
                src={allImage?.downloadArrowIcon}
                alt=""
                onClick={() => handleOpenLink(value?.url)}
              />
            </span>
          </div>
        );
      },
    },
    {
      key: 'invoice_id',
      sortable: true,
      header: 'Invoice ID',
    },
    {
      key: 'property',
      sortable: true,
      header: 'Property',
      render: (value) => {
        return <span> {(value && value?.address) || ''}</span>;
      },
    },
    {
      key: 'dates',
      sortable: false,
      header: 'Subscription Start Date',
      render: (value) => {
        return (
          <span>
            {(value &&
              value?.start_on &&
              moment(new Date(value?.start_on)).format(dateFormatTable)) ||
              ''}
          </span>
        );
      },
    },
    {
      key: 'endDate',
      sortable: true,
      header: 'Subscription end Date',
      render: (value) => {
        return (
          <span>
            {(value &&
              value?.expire_on &&
              moment(new Date(value?.expire_on)).format(dateFormatTable)) ||
              ''}
          </span>
        );
      },
    },
    {
      key: 'amount',
      sortable: true,
      header: 'Amount',
      render: (value) => {
        return <span> ${(value && value?.total && value?.total) || '0'}</span>;
      },
    },
  ];

  return (
    <>
      <div className="applicants-table-wrap">
        {toaster && (
          <Toaster
            title={error?.title}
            message={error?.message}
            type={error?.type}
            handleCloseToaster={handleCloseToaster}
          />
        )}
        <div className="applicants-listing-title">
          <h4>Payment Details</h4>
        </div>
        <DataTableControl
          filter={filter}
          columns={headingData}
          getData={getTableData}
          sortable
          showPagination
          noSelection
          loading={loading}
        />
      </div>
    </>
  );
}
