import React from 'react';
import DatePickerControl from '../Common/DatePicker';
import TextFieldControl from '../Common/TextField';
import { dynamicId, validateDate } from '../../Utils/common';
import moment from 'moment';
import { momentDateFormat } from '../../Utils/constants';

export default function ViewDetailModal({ selectedApplicant }) {
  if (!selectedApplicant) {
    return null;
  }

  const {
    adult_occupants,
    current_address,
    building,
    current_monthly_rent,
    date,
    dob,
    email,
    employer_name,
    first_name,
    how_long_worked,
    is_employed,
    last_name,
    leave_reason,
    minor_occupants,
    monthly_household_income,
    other_comments,
    phone,
    phone_supervisor_or_hr,
    property_owner_notes,
    where_hear_aboutUs,
    propertyOwner_contact_applicant,
  } = selectedApplicant || {};

  return (
    <div className="applicants-form">
      <div className="cds--row">
        <div className="cds--col mb-15">
          <DatePickerControl
            value={
              date ? moment(validateDate(date)).format(momentDateFormat) : ''
            }
            id={dynamicId()}
            labelText="Date*"
            name="dateAvailability"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={minor_occupants || 0}
            labelText="# of minor occupants"
            placeholder="# of minor occupants"
            name="occupants"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={employer_name || ''}
            labelText="Employer Name"
            placeholder="Employer Name"
            name="name"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={building || ''}
            labelText="Building"
            placeholder="Building"
            name="building"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={current_address || ''}
            labelText="Current Address"
            placeholder="Current Address"
            name="address"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={phone_supervisor_or_hr || ''}
            labelText="Phone # of supervisor / HR"
            placeholder="Phone # of supervisor / HR"
            name="phone"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={`${first_name} ${last_name}` || 0}
            labelText="Full Name"
            placeholder="Full Name"
            name="name"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={current_monthly_rent || 0}
            labelText="Current Monthly Rent"
            placeholder="Current Monthly Rent"
            name="rent"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={how_long_worked || 0}
            labelText="How long have you worked there?"
            placeholder="How long have you worked there?"
            name="worked"
          />
        </div>
        <div className="cds--col mb-15">
          <DatePickerControl
            labelText="Date of Birth"
            name="birth"
            value={
              dob ? moment(validateDate(dob)).format(momentDateFormat) : ''
            }
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={leave_reason || 'N/A'}
            labelText="Reason for leaving"
            placeholder="Reason for leaving"
            name="reason"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={where_hear_aboutUs || ''}
            labelText="Where did you hear about us?"
            placeholder="Where did you hear about us?"
            name="about"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Email"
            placeholder="Email"
            name="email"
            id={dynamicId()}
            value={email}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Are you Employed"
            placeholder="Are you Employed"
            name="is_employed"
            id={dynamicId()}
            value={is_employed ? 'Yes' : 'No'}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={other_comments || ''}
            labelText="Other comments (optional)"
            placeholder="abc"
            name="comments"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={phone || ''}
            labelText="Phone"
            placeholder="Phone"
            name="phone"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={adult_occupants || 0}
            labelText="How many people has an income in your household?"
            placeholder="2"
            name="people"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={property_owner_notes || ''}
            labelText="Property Owner Notes"
            placeholder="Lorem ipsum dolor sit amet"
            name="owner-notes"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={adult_occupants || 0}
            labelText="# of adult occupants"
            placeholder="2"
            name="adult"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={monthly_household_income || 0}
            labelText="Monthly Combined household after-tax income"
            placeholder=""
            name="tax"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={propertyOwner_contact_applicant || 'No'}
            labelText="Property owner made contact to applicant"
            placeholder=""
            name="tax"
          />
        </div>
      </div>
    </div>
  );
}
