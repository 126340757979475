import { Logout } from '@carbon/icons-react';
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
  Theme,
} from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeUserLoginDetails } from '../../Redux/Actions/User';
import { loggedOutUser, userAuth, isLoggedIn } from '../../Utils/common';
import { LandLordMenuList } from './SidebarMenuList';
import { allImage } from '../../Utils/allImageImport';

export default function SideBar({ className }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [authType, setAuthType] = useState(null);
  const [menuList, setMenuList] = useState(LandLordMenuList || []);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const info = isLoggedIn();
    setUserData(info);
    setAuthType(info?.role);
  }, []);

  useEffect(() => {
    menuList !== LandLordMenuList && setMenuList(LandLordMenuList || []);
    //react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const info = userAuth();
    setAuthType(info || null);
  }, []);

  const handleLogout = () => {
    loggedOutUser();
    dispatch(removeUserLoginDetails());
    navigate('/login');
    window?.google?.accounts?.id?.disableAutoSelect();
  };

  const handleManagePathRedirect = (value) => {
    return navigate(value);
  };

  const handleManageClassName = (value) => {
    return `cursor-pointer ${pathname === value ? 'active-menu' : ''}`;
  };

  return (
    <Theme theme="white">
      <SideNav
        isFixedNav
        expanded={true}
        aria-label="Side navigation"
        className={`side-nav ${className}`}
      >
        {authType && (
          <>
            {/* <div className="logo">
              <img src={allImage?.logo} alt="logo" />
              <span>Curated Tenancy</span>
            </div> */}
            <SideNavItems>
              {menuList &&
                menuList.length > 0 &&
                menuList.map((menu, index) => {
                  if (!menu?.subMenu) {
                    return (
                      <SideNavLink
                        key={index}
                        // isActive={
                        //   menu?.path === pathname || pathname?.match(menu?.path)
                        //     ? true
                        //     : false
                        // }
                        onClick={() => handleManagePathRedirect(menu?.path)}
                        className={handleManageClassName(menu?.path)}
                      >
                        <img src={menu?.icon} alt={menu?.title} /> {menu?.title}
                      </SideNavLink>
                    );
                  }
                  return (
                    <SideNavMenu
                      key={index}
                      isActive={pathname?.includes(menu?.path)}
                      title={menu?.title}
                      className={handleManageClassName(menu?.path)}
                      defaultExpanded={pathname?.includes(menu?.path)}
                    >
                      {menu?.menu &&
                        menu?.menu?.length > 0 &&
                        menu?.menu?.map((subMenu, index) => {
                          return (
                            <SideNavMenuItem
                              key={index}
                              className={handleManageClassName(subMenu?.path)}
                              // isActive={
                              //   subMenu?.path === pathname ||
                              //   pathname?.match(menu?.path)
                              //     ? true
                              //     : false
                              // }
                              onClick={() =>
                                handleManagePathRedirect(subMenu?.path)
                              }
                            >
                              <img src={subMenu?.icon} alt={subMenu?.title} />{' '}
                              {subMenu?.title}
                            </SideNavMenuItem>
                          );
                        })}
                    </SideNavMenu>
                  );
                })}
            </SideNavItems>
          </>
        )}
        <div className="side-nav-bottom">
          <SideNavItems>
            <SideNavLink onClick={handleLogout} className="cursor-pointer">
              <Logout /> Logout
            </SideNavLink>
          </SideNavItems>
          <div className="logged-user">
            <h6>{`${userData?.first_name} ${userData?.last_name}`}</h6>
            <span>{userData?.email}</span>
          </div>
        </div>
      </SideNav>
    </Theme>
  );
}
