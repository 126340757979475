const propertiesListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Properties',
    path: '/properties',
    redirect: false,
  },
];
const propertiesAddPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Properties',
    path: '/properties',
    redirect: true,
  },
  {
    title: 'Add Property',
    path: '/properties/add-property',
    redirect: false,
  },
];
const propertiesDetailsPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Properties',
    path: '/properties',
    redirect: true,
  },
];
const homeListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: false,
  },
];
const applicantsListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Applicants',
    path: '/applicants',
    redirect: false,
  },
];
const invoicePage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Invoice',
    path: '/invoice',
    redirect: false,
  },
];
const applicantsDetailsListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Applicants',
    path: '/applicants',
    redirect: false,
  },
  {
    title: 'Applicants Details',
    path: '/applicants/applicants-details',
    redirect: false,
  },
];
export const breadcrumb = {
  propertiesListPage,
  homeListPage,
  propertiesAddPage,
  propertiesDetailsPage,
  applicantsListPage,
  applicantsDetailsListPage,
  invoicePage,
};
