import React from 'react';
import SiteHeading from '../../Common/SiteHeading';
import ApplicantsComponent from '../../Applicants';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import { useLocation, useNavigate } from 'react-router-dom';
import { propertiesAPI } from '../../../API/properties';
import { useState } from 'react';
import { useEffect } from 'react';
import MainLoader from '../../Common/Loader/MainLoader';
import Toaster from '../../Common/Toaster';

let customMenu = [];
const Applicants = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, pathname } = location;
  const [details, setDetails] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [toastError, setToastError] = useState({});

  useEffect(() => {
    if (details?.address) {
      if (!customMenu.some(({ title }) => title === details?.address)) {
        customMenu = [];
        customMenu = breadcrumb?.propertiesDetailsPage.slice();
        customMenu.push({
          title: details?.address,
          path: `/properties/property-detail/${details?._id}`,
          redirect: true,
        });
        customMenu.push({
          title: 'Applicants',
          path: `/properties/property-detail/applicants/${details?._id}`,
          redirect: false,
        });
        setRefresh(!refresh);
      }
    }
  }, [details]);

  useEffect(() => {
    if (state?.id) {
      setDetails(state?.details || {});
    } else {
      const id = pathname?.split('/')[4] || null;
      if (id) {
        handleGetPropertyValue(id);
      } else {
        navigate('/properties');
      }
    }
  }, [state?.id]);

  const handleGetPropertyValue = async (id) => {
    setLoading(true);
    const res = await propertiesAPI?.getSingleProperty(id);
    if (res && res?.status === true) {
      setDetails(res?.data || {});
      toaster && setToaster(false);
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <MainLoader />}
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      <SiteHeading Heading="Applicants" menu={customMenu}></SiteHeading>

      <ApplicantsComponent
        {...{ details }}
        id={state?.id || pathname?.split('/')[4]}
      />
    </>
  );
};

export default Applicants;
