import { get } from '../base';
const endPoint = 'landlord/invoice';

const getAllInvoices = async () => {
  const response = await get(`${endPoint}`);
  return response;
};

const getSingleInvoice = async (id) => {
  const response = await get(`${endPoint}/${id}`);
  return response;
};

export const invoiceAPI = {
  getSingleInvoice,
  getAllInvoices,
};
