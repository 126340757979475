import React, { useEffect, useState } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableBatchActions,
  TableBatchAction,
  TableToolbar,
  TableSelectAll,
  TableSelectRow,
} from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import SectionLoader from '../Loader/SectionLoader';
import PaginationComponent from '../Pagination';
import { dynamicId } from '../../../Utils/common';

export default function DataTableControl(props) {
  const {
    columns,
    title,
    batchActionClick,
    sortable,
    showPagination,
    loading,
    getData,
    filter,
    staticData,
  } = props;
  const [docs, setDocs] = useState([]);
  const showActions = batchActionClick && typeof batchActionClick == 'function';
  const [pagination, setPagination] = useState({
    page: props.page || 1,
    limit: props.limit || 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 1,
    pagingCounter: props.pagingCounter || 0,
    prevPage: false,
    totalDocs: 0,
    totalPages: 0,
  });

  const { limit, page, totalDocs } = pagination || {};
  useEffect(() => {
    async function fetchData() {
      const res = await getData({
        $limit: pagination.limit,
        $page: pagination.page,
        filter: filter,
      });
      setDocs(res?.docs || res || []);
      setPagination(res);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleChangePagination = async (e) => {
    const value = { filter: filter, $page: e?.page, $limit: e?.pageSize };
    setPagination(value);
    const res = await getData(value);
    setDocs(res?.docs || res || []);
    setPagination(res);
  };

  // const handleSortChange = async (field, order) => {
  //   const value = {
  //     filter: filter,

  //     $page: pagination.page,
  //     $limit: pagination.limit,
  //   };
  //   if (field) {
  //     value['$sort'] = {
  //       [field]: order === 'DESC' ? 1 : -1,
  //     };
  //   }
  //   setPagination({ ...value, ...pagination });
  //   const res = await getData(value);
  //   setDocs(res?.docs || []);
  // };

  return (
    <>
      <DataTable
        rows={staticData ? getData : docs || []}
        headers={columns}
        // future if you need any modification then we can use below code.
        // headers={props.columns.map((data) => {
        //   //manage column props here that you want to make it available in Header component
        //   return {
        //     header: data.title,
        //     key: data.key,
        //     sortable: data.sort able
        //   }
        // })}
        isSortable={sortable ? true : false}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getBatchActionProps,
          getToolbarProps,
          selectedRows,
          getSelectionProps,
        }) => {
          const batchActionProps = getBatchActionProps();
          const [sortedColum] = headers
            .map((header) => {
              const data = getHeaderProps({
                header,
              });
              return data;
            })
            .filter((data) => data.isSortHeader === true);
          if (sortedColum) {
            // return handleSortChange(sortedColum.key, sortedColum.sortDirection);
          } else {
            // return handleSortChange(null);
          }
          return (
            <TableContainer title={title}>
              {loading && <SectionLoader />}
              {showActions &&
                batchActionProps &&
                batchActionProps?.shouldShowBatchActions === true && (
                  <TableToolbar {...getToolbarProps()}>
                    <TableBatchActions {...batchActionProps}>
                      <TableBatchAction
                        tabIndex={
                          batchActionProps.shouldShowBatchActions ? 0 : -1
                        }
                        renderIcon={TrashCan}
                        onClick={() => batchActionClick(selectedRows, 'delete')}
                      >
                        Delete
                      </TableBatchAction>
                    </TableBatchActions>
                  </TableToolbar>
                )}
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {showActions && <TableSelectAll {...getSelectionProps()} />}
                    {headers.map((header) => (
                      <TableHeader
                        {...getHeaderProps({
                          header,
                          isSortable: header?.sortable === true,
                        })}
                      >
                        {header?.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows &&
                    rows?.length > 0 &&
                    rows?.map((row) => (
                      <TableRow key={dynamicId()}>
                        {showActions && (
                          <TableSelectRow {...getSelectionProps({ row })} />
                        )}
                        {row &&
                          row?.cells &&
                          row?.cells?.length > 0 &&
                          row?.cells?.map((cell, index) => (
                            <TableCell key={cell?.id}>
                              {!columns[index]?.render && cell?.value}
                              {columns[index]?.render &&
                                columns[index]?.render(cell?.value)}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      {showPagination === true && (
        <PaginationComponent
          pageSize={limit}
          page={page}
          totalItems={totalDocs}
          handleChange={handleChangePagination}
        />
      )}
    </>
  );
}
