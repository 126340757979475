import { ProgressIndicator, ProgressStep } from '@carbon/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { propertiesAPI } from '../../API/properties';
import { allImage } from '../../Utils/allImageImport';
import { getBase64 } from '../../Utils/common';
import { momentDateFormat } from '../../Utils/constants';
import { number } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';
import DatePickerControl from '../Common/DatePicker';
import DropdownComboBoxControl from '../Common/Dropdown/comboBox';
import MultiSelectDropdownControl from '../Common/Dropdown/multiSelectDropdown';
import MainLoader from '../Common/Loader/MainLoader';
import TextAreaControl from '../Common/TextArea';
import TextFieldControl from '../Common/TextField';
import Toaster from '../Common/Toaster';

export default function AddPropertyComponent() {
  const image = 'image';
  const [step, setStep] = useState(1);
  const [addPropertyForm, setAddPropertyForm] = useState({});
  const [addPropertyFormError, setAddPropertyFormError] = useState({});
  const [imageList, setImageList] = useState([]);
  const [imageBase64List, setImageBase64List] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dropdownValueList, setDropdownValueList] = useState({});
  const [toastError, setToastError] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (refresh === image) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    getStaticDropdownList();
  }, []);

  const handleLoadSteps = () => {
    switch (step) {
      case 1:
        return handleLoadFirstStep();
      case 2:
        return handleLoadSecondStep();
      case 3:
        return handleLoadThirdStep();

      default:
        return handleLoadFirstStep();
    }
  };

  const stepLabel = () => {
    return `Step ${step}`;
  };

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setAddPropertyForm({
      ...addPropertyForm,
      [name]: value,
    });
  };

  const handleChangeImage = (e) => {
    const { files } = e?.target || e;
    if (files && files?.length) {
      setImageList([...imageList, ...files]);
      const URLValue = [];
      [...files].map(async (image) => {
        const urls = await getBase64(image);
        URLValue.push(urls);
        setImageBase64List([...imageBase64List, ...URLValue]);
      });
      setRefresh('image');
    }
  };

  const handleLoadFirstStep = () => {
    return (
      <div className="cds--row">
        <div className="cds--col-lg-16 mb-15">
          <h5>{stepLabel()}</h5>
        </div>
        <div className="cds--col-lg-16 mb-15">
          <TextFieldControl
            labelText="Address*"
            placeholder="Enter Address"
            name="address"
            id="address"
            value={addPropertyForm?.address || ''}
            onChange={handleChange}
          />
          {addPropertyFormError?.address && (
            <label className="red-text">{addPropertyFormError?.address}</label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <TextFieldControl
            labelText="Monthly Rent*"
            placeholder="Monthly Rent"
            name="monthlyRent"
            id="monthlyRent"
            value={addPropertyForm?.monthlyRent || ''}
            onChange={handleChange}
          />
          {addPropertyFormError?.monthlyRent && (
            <label className="red-text">
              {addPropertyFormError?.monthlyRent}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <TextFieldControl
            labelText="Security Deposit*"
            placeholder="Security Deposit"
            name="securityDeposit"
            id="securityDeposit"
            value={addPropertyForm?.securityDeposit || ''}
            onChange={handleChange}
          />
          {addPropertyFormError?.securityDeposit && (
            <label className="red-text">
              {addPropertyFormError?.securityDeposit}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <TextFieldControl
            labelText="Total Bedrooms*"
            placeholder="bedroom"
            name="totalBedroom"
            id="bedroom"
            value={addPropertyForm?.totalBedroom || ''}
            onChange={handleChange}
          />
          {addPropertyFormError?.totalBedroom && (
            <label className="red-text">
              {addPropertyFormError?.totalBedroom}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <TextFieldControl
            labelText="Total Bathroom*"
            placeholder="bathroom"
            name="totalBathroom"
            id="bathroom"
            value={addPropertyForm?.totalBathroom || ''}
            onChange={handleChange}
          />
          {addPropertyFormError?.totalBathroom && (
            <label className="red-text">
              {addPropertyFormError?.totalBathroom}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <DatePickerControl
            labelText="Date Availability*"
            name="dateAvailability"
            onChange={(e) => {
              const event = {
                target: {
                  name: 'dateAvailability',
                  value: e && e?.length ? e?.[0] : '',
                },
              };
              handleChange(event);
            }}
            value={
              addPropertyForm?.dateAvailability
                ? moment(addPropertyForm?.dateAvailability).format(
                    momentDateFormat
                  )
                : ''
            }
          />
          {addPropertyFormError?.dateAvailability && (
            <label className="red-text">
              {addPropertyFormError?.dateAvailability}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <TextFieldControl
            labelText="Square footage(optional)"
            placeholder="Square footage"
            name="squareFootage"
            id="Square footage(optional)"
            value={addPropertyForm?.squareFootage || ''}
            onChange={handleChange}
          />
          {addPropertyFormError?.squareFootage && (
            <label className="red-text">
              {addPropertyFormError?.squareFootage}
            </label>
          )}
        </div>
      </div>
    );
  };

  const handleLoadSecondStep = () => {
    return (
      <div className="cds--row">
        <div className="cds--col-lg-16 mb-15">
          <h5>{stepLabel()}</h5>
        </div>
        <div className="cds--col-lg-8 mb-15">
          <DropdownComboBoxControl
            ariaLabel="ComboBox"
            id="carbon-combobox-example"
            // itemToString={(item) => (item ? item.text : '')}
            label="Select"
            titleText="Pet Policy*"
            items={dropdownValueList?.pet_policy || []}
            name="petPolicy"
            value={addPropertyForm?.petPolicy || ''}
            onChange={(e) => {
              const event = {
                target: {
                  value: e?.selectedItem?.label,
                  name: 'petPolicy',
                },
              };
              handleChange(event);
            }}
          />
          {addPropertyFormError?.petPolicy && (
            <label className="red-text">
              {addPropertyFormError?.petPolicy}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <DropdownComboBoxControl
            ariaLabel="ComboBox"
            id="carbon-combobox-example"
            // itemToString={(item) => (item ? item.text : '')}
            label="Select"
            name="leaseDuration"
            titleText="Lease duration*"
            value={addPropertyForm?.leaseDuration || ''}
            items={dropdownValueList?.lease_duration || []}
            onChange={(e) => {
              const event = {
                target: {
                  value: e?.selectedItem?.label,
                  name: 'leaseDuration',
                },
              };
              handleChange(event);
            }}
          />
          {addPropertyFormError?.leaseDuration && (
            <label className="red-text">
              {addPropertyFormError?.leaseDuration}
            </label>
          )}
        </div>
        <div className="cds--col-lg-8 mb-15">
          <MultiSelectDropdownControl
            ariaLabel="ComboBox"
            id="carbon-combobox-example"
            // itemToString={(item) => (item ? item.text : '')}
            label="Select"
            name="amenities"
            titleText="Amenities(choose one or more options)"
            initialSelectedItems={addPropertyForm?.amenities || []}
            items={dropdownValueList?.amenities || []}
            onChange={(e) => {
              const event = {
                target: {
                  value: e?.selectedItems || [],
                  name: 'amenities',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col-lg-8 mb-15">
          <MultiSelectDropdownControl
            initialSelectedItems={addPropertyForm?.utilities || []}
            ariaLabel="ComboBox"
            id="carbon-combobox-example"
            // itemToString={(item) => (item ? item.text : '')}
            label="Select"
            titleText="Utilities included in rent(choose one or more options)"
            items={dropdownValueList?.utilities || []}
            name="utilities"
            onChange={(e) => {
              const event = {
                target: {
                  value: e?.selectedItems || [],
                  name: 'utilities',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col-lg-16 mb-15">
          <TextAreaControl
            id="Description"
            labelText="Description"
            name="description"
            value={addPropertyForm?.description || ''}
            placeholder="Enter property description"
            rows={4}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };

  const handleLoadThirdStep = () => {
    return (
      <div className="cds--row">
        <div className="cds--col-lg-16 mb-15">
          <h5>{stepLabel()}</h5>
        </div>
        <div className="cds--col-lg-8 mb-15">
          <span>Property images</span>
          <div className="upload_text">
            {imageBase64List &&
              imageBase64List?.length > 0 &&
              imageBase64List?.map((image, index) => {
                return (
                  <div className="upload-image">
                    <span onClick={() => handleRemoveImage(index)}>
                      <img src={allImage?.closeIcon} alt="delete" />
                    </span>
                    <img key={index} src={image || ''} alt={'property'} />
                  </div>
                );
              })}
            {refresh !== 'image' && (
              <div className="file-upload">
                <TextFieldControl
                  multiple
                  name="image"
                  labelText=""
                  style={{ display: 'none' }}
                  type="file"
                  id="upload-profile-img"
                  onChange={handleChangeImage}
                />
                <label htmlFor="upload-profile-img">
                  <img src={allImage?.uploadIcon} alt="upload" />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleStepOneError = () => {
    const {
      address,
      monthlyRent,
      securityDeposit,
      totalBathroom,
      totalBedroom,
      dateAvailability,
    } = addPropertyForm;
    let error = {};
    if (!address) {
      error.address = 'Address is required';
    } else {
      delete error.address;
    }
    if (!monthlyRent) {
      error.monthlyRent = 'Monthly rent is required';
    } else {
      const value = number(monthlyRent);
      if (!value) {
        error.monthlyRent = 'Monthly rent must be in number value';
      } else {
        delete error.monthlyRent;
      }
    }
    if (!securityDeposit) {
      error.securityDeposit = 'Security deposit is required';
    } else {
      const value = number(securityDeposit);
      if (!value) {
        error.securityDeposit = 'Security deposit must be in number value';
      } else {
        delete error.securityDeposit;
      }
    }
    if (!totalBedroom) {
      error.totalBedroom = 'Total bedroom is required';
    } else {
      const value = number(totalBedroom);
      if (!value) {
        error.totalBedroom = 'total bedroom count must be in number value';
      } else {
        delete error.totalBedroom;
      }
    }
    if (!totalBathroom) {
      error.totalBathroom = 'Total bathroom is required';
    } else {
      const value = number(totalBathroom);
      if (!value) {
        error.totalBathroom = 'Total bathroom count must be in number value';
      } else {
        delete error.totalBathroom;
      }
    }
    if (!dateAvailability) {
      error.dateAvailability = 'Availability date is required';
    } else {
      const value = moment(dateAvailability)?._isValid;
      if (!value) {
        error.dateAvailability = 'Please enter valid date';
      } else {
        delete error.dateAvailability;
      }
    }
    if (Object.keys(error).length) {
      setAddPropertyFormError(error);
      return error;
    } else {
      let cloneError = { ...addPropertyFormError };
      delete cloneError.address;
      delete cloneError.monthlyRent;
      delete cloneError.securityDeposit;
      delete cloneError.totalBathroom;
      delete cloneError.totalBedroom;
      delete cloneError.dateAvailability;
      setAddPropertyFormError(cloneError);
    }
    return null;
  };

  const handleStepTwoError = () => {
    const { petPolicy, leaseDuration } = addPropertyForm;
    let error = {};
    if (!petPolicy) {
      error.petPolicy = 'Pet policy is required';
    } else {
      delete error.petPolicy;
    }
    if (!leaseDuration) {
      error.leaseDuration = 'Lease duration is required';
    } else {
      delete error.leaseDuration;
    }

    if (Object.keys(error).length) {
      setAddPropertyFormError({ ...addPropertyFormError, ...error });
      return error;
    } else {
      let cloneError = { ...addPropertyFormError };
      delete cloneError.petPolicy;
      delete cloneError.leaseDuration;
      setAddPropertyFormError(cloneError);
    }
    return null;
  };

  const handleChangeStep = (value) => {
    let error = null;
    if (+value === 2) {
      error = handleStepOneError();
    }
    if (+value === 3 && error === null) {
      error = handleStepTwoError();
    }
    if (error === null) {
      if (value) setStep(+value);
    }
    return null;
  };

  const handlePropertySave = async () => {
    const fromData = new FormData();
    fromData.append('address', addPropertyForm?.address);
    fromData.append('bedrooms', addPropertyForm?.totalBedroom);
    fromData.append('bathrooms', addPropertyForm?.totalBathroom);
    fromData.append('monthly_rent', addPropertyForm?.monthlyRent);
    fromData.append('security_deposit', addPropertyForm?.securityDeposit);
    fromData.append('square_footage', addPropertyForm?.squareFootage);
    fromData.append(
      'date_available',
      new Date(addPropertyForm?.dateAvailability)
    );
    fromData.append('lease_duration', addPropertyForm?.leaseDuration);
    fromData.append('pet_policy', addPropertyForm?.petPolicy);
    fromData.append(
      'amenities',
      (addPropertyForm?.amenities?.length &&
        addPropertyForm?.amenities.map((list) => {
          return list?.label;
        })) ||
        []
    );
    fromData.append(
      'utilities',
      (addPropertyForm?.utilities?.length &&
        addPropertyForm?.utilities.map((list) => {
          return list?.label;
        })) ||
        []
    );
    fromData.append('description', addPropertyForm?.description);
    imageList &&
      imageList.length &&
      imageList.map((image) => {
        return fromData.append('images', image);
      });
    setLoading(true);
    const res = await propertiesAPI?.addProperty(fromData, {
      contentType: 'multipart/form-data',
    });
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      const { _id } = res.data;
      redirectToPayment(_id);
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const redirectToPayment = async (id) => {
    const base_url = window?.location.origin;
    const redirect_urls = {
      success: `${base_url}/properties`,
      failed: `${base_url}/properties`,
      cancel: `${base_url}/properties`,
    };
    const response = await propertiesAPI.getPropertyPaymentUrl(
      id,
      redirect_urls
    );
    const { url } = response.data;
    window.location.href = url;
    return null;
  };

  const handleRemoveImage = (index) => {
    if (~index) {
      const imageClone = imageList.slice();
      const base64Clone = imageBase64List.slice();
      base64Clone.splice(index, 1);
      imageClone.splice(index, 1);
      setImageList(imageClone);
      setImageBase64List(base64Clone);
    }
  };

  const getStaticDropdownList = async () => {
    const res = await propertiesAPI?.getStaticProperties();
    if (res && res?.status === true) {
      let filterValue = {};
      Object.keys(res?.data).map((data) => {
        return (filterValue[data] = res?.data[data].map((list, index) => {
          return { id: index, label: list };
        }));
      });
      setDropdownValueList(filterValue || {});
      setToaster(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  return (
    <div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <p>You can always come back to edit this.</p>
      <div className="add-properties-step">
        <ProgressIndicator>
          {step === 1 ? (
            <ProgressStep current label="Step 1" />
          ) : (
            <ProgressStep complete label="Step 1" />
          )}
          {step === 2 ? (
            <ProgressStep current label="Step 2" />
          ) : step === 3 ? (
            <ProgressStep complete label="Step 1" />
          ) : (
            <ProgressStep label="Step 2" />
          )}
          {step === 3 ? (
            <ProgressStep current label="Step 3" />
          ) : (
            <ProgressStep label="Step 3" />
          )}
        </ProgressIndicator>
      </div>
      <div className="properties-step-box">
        <div className="step-inner">{handleLoadSteps()}</div>
        <div className="step-btn">
          {step !== 1 && (
            <ButtonControl
              kind="secondary"
              disabled={step === 1 ? true : false}
              onClick={() => handleChangeStep(step > 1 ? step - 1 : null)}
            >
              Back
            </ButtonControl>
          )}
          <ButtonControl
            kind="primary"
            onClick={() => {
              step === 3
                ? handlePropertySave()
                : handleChangeStep(step !== 3 ? step + 1 : null);
            }}
          >
            {step === 3 ? 'Save' : 'Next'}
          </ButtonControl>
        </div>
      </div>
    </div>
  );
}
