import { get, patch, post } from '../base';
const endPoint = 'landlord/applicants';

const getPropertyWiseApplicant = async (id, params) => {
  const response = await get(`${endPoint}/list/${id}`, params);
  return response;
};

const getApplicant = async (id, params) => {
  const response = await get(`${endPoint}/${id}`, params);
  return response;
};

const getAllApplicants = async (body) => {
  const response = await post(`${endPoint}`, body);
  return response;
};

const updateApplicant = async (id, data) => {
  const response = await patch(`${endPoint}/${id}`, data);
  return response;
};

export const applicantAPI = {
  getApplicant,
  getAllApplicants,
  getPropertyWiseApplicant,
  updateApplicant,
};
