import React from 'react';
import DatePickerControl from '../Common/DatePicker';
import TextFieldControl from '../Common/TextField';
import ButtonControl from '../Common/Button';
import { dynamicId, validateDate } from '../../Utils/common';
import moment from 'moment';
import { momentDateFormat } from '../../Utils/constants';
import DropdownComboBoxControl from '../Common/Dropdown/comboBox';

const ApplicantsDetailsComponent = ({
  applicantDetails,
  handleChange,
  handleUpdateApplicantDetails,
  isDisabledSaveBtn
}) => {
  if (applicantDetails && !Object?.keys(applicantDetails)?.length) {
    return null;
  }
  const {
    adult_occupants,
    current_address,
    current_monthly_rent,
    date,
    building,
    dob,
    email,
    employer_name,
    first_name,
    how_long_worked,
    is_employed,
    last_name,
    leave_reason,
    minor_occupants,
    monthly_household_income,
    other_comments,
    phone,
    phone_supervisor_or_hr,
    property_owner_notes,
    where_hear_aboutUs,
    propertyOwner_contact_applicant,
    household_income_people
  } = applicantDetails || {};

  return (
    <div className="applicants-form">
      <div className="cds--row">
        <div className="cds--col mb-15">
          <DatePickerControl
            labelText="Date"
            name="date"
            value={
              date ? moment(validateDate(date)).format(momentDateFormat) : ''
            }
            id={dynamicId()}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'date',
                  value: e && e?.length ? e?.[0] : '',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="# of minor occupants"
            placeholder="# of minor occupants"
            name="minor_occupants"
            id={dynamicId()}
            value={minor_occupants || '0'}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Employer Name"
            placeholder="Employer Name"
            name="employer_name"
            id={dynamicId()}
            value={employer_name || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Building"
            placeholder="Building"
            name="building"
            id={dynamicId()}
            value={building || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Current Address"
            placeholder="Current Address"
            name="current_address"
            id={dynamicId()}
            value={current_address || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Phone # of supervisor / HR"
            placeholder="Phone # of supervisor / HR"
            name="phone_supervisor_or_hr"
            id={dynamicId()}
            value={phone_supervisor_or_hr || ''}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Full Name"
            placeholder="Full Name"
            name="fullName"
            id={dynamicId()}
            value={`${first_name} ${last_name}` || 0}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Current Monthly Rent"
            placeholder="Current Monthly Rent"
            name="current_monthly_rent"
            id={dynamicId()}
            value={current_monthly_rent || '0'}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="How long have you worked there?"
            placeholder="How long have you worked there?"
            name="how_long_worked"
            id={dynamicId()}
            value={how_long_worked || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <DatePickerControl
            labelText="Date of Birth"
            name="dob"
            value={
              dob ? moment(validateDate(dob)).format(momentDateFormat) : ''
            }
            id={dynamicId()}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'dob',
                  value: e && e?.length ? e?.[0] : '',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Reason for leaving"
            placeholder="Reason for leaving"
            name="leave_reason"
            id={dynamicId()}
            value={leave_reason || '-'}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Where did you hear about us?"
            placeholder="Where did you hear about us?"
            name="where_hear_aboutUs"
            onChange={handleChange}
            id={dynamicId()}
            value={where_hear_aboutUs || ''}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            id={dynamicId()}
            value={email}
          />
        </div>
        <div className="cds--col mb-15">
          <DropdownComboBoxControl
            ariaLabel="ComboBox"
            label="Select"
            titleText="Are you Employed"
            items={[
              { id: 1, label: 'Yes' },
              { id: 2, label: 'No' },
            ]}
            id={dynamicId()}
            name="is_employed"
            value={is_employed ? 'Yes' : 'No'}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'is_employed',
                  value: e?.selectedItem?.label === 'Yes' ? true : false,
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Other comments (optional)"
            placeholder="abc"
            name="other_comments"
            onChange={handleChange}
            id={dynamicId()}
            value={other_comments || ''}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Phone"
            placeholder="Phone"
            name="phone"
            id={dynamicId()}
            value={phone || ''}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="How many people has an income in your household?"
            placeholder="2"
            name="household_income_people"
            id={dynamicId()}
            value={household_income_people || 0}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Property Owner Notes"
            placeholder="Property owner notes"
            name="property_owner_notes"
            id={dynamicId()}
            value={property_owner_notes || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="# of adult occupants"
            placeholder="2"
            name="adult_occupants"
            id={dynamicId()}
            value={adult_occupants || 0}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Monthly Combined household after-tax income"
            placeholder="Monthly Combined household after-tax income"
            name="monthly_household_income"
            id={dynamicId()}
            value={monthly_household_income || 0}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <DropdownComboBoxControl
            ariaLabel="ComboBox"
            label="Select"
            titleText="Property owner made contact to applicant"
            items={[
              { id: 1, label: 'Yes' },
              { id: 2, label: 'No' },
            ]}
            id={dynamicId()}
            name="propertyOwner_contact_applicant"
            value={propertyOwner_contact_applicant ? 'Yes' : 'No'}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'propertyOwner_contact_applicant',
                  value: e?.selectedItem?.label === 'Yes' ? true : false,
                },
              };
              handleChange(event);
            }}
          />
        </div>
      </div>
      <div className="cds--row">
        <div className="cds--col mb-15">
          <ButtonControl kind="primary" onClick={handleUpdateApplicantDetails} disabled={isDisabledSaveBtn}>
            Save
          </ButtonControl>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsDetailsComponent;
