import { get, post } from '../base';
const endPoint = 'landlord/lead';

const getAllLeadsCount = async (body) => {
  const response = await post(`${endPoint}`, body);
  return response;
};

export const leadAPI = {
  getAllLeadsCount,
};
