import { Information } from '@carbon/icons-react';
import { Checkbox, Form, Stack, TextInput } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { authAPI } from '../../API/Auth';
import Toaster from '../../Components/Common/Toaster';
import MainLoader from '../../Components/Common/Loader/MainLoader';
import { CLIENT_ID, GOOGLE_REDIRECT_URL } from '../../Utils/config';
import { setUserLoginDetails } from '../../Redux/Actions/User';
import {
  isLoggedIn,
  setLoggedUser,
  setLoggedUserToken,
  userAuth,
} from '../../Utils/common';
import { emailValidation } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';
import { allImage } from '../../Utils/allImageImport';

const LoginComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});
  const [loginError, setLoginError] = useState({
    email: false,
    password: false,
  });

  useEffect(() => {
    window?.google?.accounts?.id?.initialize({
      client_id: CLIENT_ID,
      ux_mode: 'redirect',
      login_uri: `${GOOGLE_REDIRECT_URL}landlord/login/google?redirect=${window?.location?.href}`,
    });
    window?.google?.accounts?.id?.renderButton(
      document.getElementById('signInDiv'),
      {
        theme: 'outline',
        size: 'medium',
      }
    );
  }, []);

  useEffect(() => {
    const token = new URL(window.location.href).searchParams.get('token');
    if (token) {
      try {
        const userData = jwt_decode(token);
        if (userData && Object.keys(userData)?.length) {
          setLoggedUser(userData);
          setLoggedUserToken(token);
          dispatch(setUserLoginDetails(userData));
          document.getElementById('signInDiv').hidden = true;
          navigate('/home');
        }
      } catch (e) {}
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    const userData = isLoggedIn();
    if (userData && userData?.email_verified === false) {
      return;
    }
    if (userAuth()) navigate('/home');
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setLoginForm({
      ...loginForm,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const { email, password } = loginForm;
    let error = {};
    if (!password) {
      error.password = 'Password is required';
    }
    if (!email) {
      error.email = 'Email is required';
    } else {
      const value = emailValidation(email);
      if (!value) {
        error.email = 'Please enter valid email';
      } else {
        delete error.email;
      }
    }

    if (Object.keys(error).length) {
      setLoginError(error);
      return;
    }
    setLoading(true);
    const res = await authAPI?.login(loginForm);
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      if (res?.data) {
        const userData = jwt_decode(res?.data?.token);
        setLoggedUser(userData);
        setLoggedUserToken(res?.data?.token);
        dispatch(setUserLoginDetails(userData));
        if (userData?.email_verified === true) {
          navigate('/home');
        } else {
          navigate('/email-verify');
        }
      }
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  return (
    <div className="login-form">
      <div className="logo">
        <img src={allImage?.logo} alt="logo" />
        <span>Curated Tenancy</span>
      </div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <div className="login-box">
        <h2 className="title">Sign In</h2>
        <Form>
          <Stack gap={5}>
            <TextInput
              labelText="Email"
              placeholder="Email"
              name="email"
              value={loginForm?.email || ''}
              onChange={handleChange}
              required={false}
              id="email"
            />
            {loginError?.email && (
              <label className="red-text">{loginError?.email}</label>
            )}
            <TextInput.PasswordInput
              id="password"
              labelText="Password"
              placeholder="Password"
              name="password"
              value={loginForm?.password || ''}
              onChange={handleChange}
              required={false}
            />
            {loginError?.password && (
              <label className="red-text">{loginError?.password}</label>
            )}
            <div className="remember">
              <div className="remember-inner">
                <Checkbox labelText="Remember me" id="remember" />
                <Information />
              </div>
              <Link className="forgot-password-text" to="/forgot-password">
                Forgot Password?
              </Link>
            </div>
            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleSubmit}
                tabIndex={0}
                type="button"
                fullWidth
              >
                Sign In
              </ButtonControl>
            </div>
            <div className="divider-text">
              <span>Sign in with</span>
            </div>
            <div className="external-login">
              <div id="signInDiv" data-title="Google" className="sign-in-button"></div>
              <ButtonControl
                className="ml-10"
                kind="tertiary"
                tabIndex={1}
                type="button"
              >
                <img src={allImage?.facebookIcon} alt="icon" /> Facebook
              </ButtonControl>
            </div>
            <div className="bottom-text">
              <p>
                Don't have an account? <Link to="/signup">Signup</Link>
              </p>
            </div>
          </Stack>
        </Form>
      </div>
    </div>
  );
};

export default LoginComponent;
