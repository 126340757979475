import * as FileSaver from 'file-saver';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { applicantAPI } from '../../API/Applicant';
import { allImage } from '../../Utils/allImageImport';
import { dateFormatTable } from '../../Utils/constants';
import ButtonControl from '../Common/Button';
import DataTableControl from '../Common/DataTable';
import MultiSelectDropdownControl from '../Common/Dropdown/multiSelectDropdown';
import Modal from '../Common/Modal';
import SearchFilter from '../Common/SearchFilter';
import Toaster from '../Common/Toaster';
import ViewDetailModal from './viewDetailModal';

export default function Applicants({ details, id }) {
  const location = useLocation();
  const navigation = useNavigate();
  const { pathname } = location;
  const [filter, setFilter] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [list, setList] = useState([]);
  const [applicantsViewModel, setApplicantsViewModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [refreshControl, setRefreshControl] = useState(false);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  useEffect(() => {
    if (refreshControl) {
      setRefreshControl(false);
    }
  }, [refreshControl]);

  const getTableData = async (filter) => {
    setLoading(true);
    filter.$page = filter.$page || 1;
    filter.$limit = filter.$limit || 10;
    filter.filter = filter.filter || {};
    filter.filter = {
      ...filter.filter,
      property: id || pathname?.split('/')[4],
    };
    const res = await applicantAPI?.getPropertyWiseApplicant(
      id || pathname?.split('/')[4],
      filter
    );
    if (res || !res) setLoading(false);
    if (res && res?.status === true) {
      toaster && setToaster(false);
      setList(res?.data?.docs);
      return res?.data;
    } else {
      setToaster(true);
      setError({
        ...error,
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      return [];
    }
  };

  const handleChange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };

  const handleAddFilter = () => {
    let filterData = {};
    Object.keys(filterValue).map((keyName) => {
      if (filterValue[keyName]) {
        filterData[keyName] = filterValue[keyName];
      }
    });
    setFilter(filterData);
  };

  const handleViewModal = (value) => {
    setApplicantsViewModal(true);
    const record = list?.find(({ id }) => id?.toString() === value?.toString());
    if (record) {
      setSelectedApplicant(record);
    }
  };

  const applicantsViewModalClose = () => {
    setApplicantsViewModal(false);
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const handleRedirectEdit = (value) => {
    if (value) {
      navigation(`/properties/property-detail/applicant/edit/${value}`, {
        state: {
          id: value,
          details
        },
      });
    }
  };

  const handleRedirectProperty = () => {
    if (id || pathname?.split('/')[4]) {
      navigation(
        `/properties/property-detail/${id || pathname?.split('/')[4]}`
      );
    }
  };

  const handleDownloadInCSV = () => {
    if (list && list?.length) {
      exportExcelFile();
    }
  };

  const generateExcelData = (item) => {
    return {
      'First Name': item?.first_name,
      'Last Name': item?.last_name,
      'Phone Number': item?.phone_number,
      Email: item?.email,
      BOB: item?.dob,
      'Monthly Household Income': item?.monthly_household_income,
      'Employer Name': item?.employer_name,
      'Adult Occupants': item?.adult_occupants,
      'Property Owner Notes': item?.property_owner_notes,
      Contact: item?.phone,
    };
  };

  const exportExcelFile = () => {
    const csvDataList = [];
    list?.forEach((item) => {
      csvDataList.push(generateExcelData(item));
    });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'ApplicantList';

    const ws = XLSX.utils.json_to_sheet(csvDataList);
    const wb = {
      Sheets: { data: ws },
      SheetNames: ['data'],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], {
      type: fileType,
    });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const headingData = [
    {
      key: 'id',
      sortable: false,
      header: ' ',
      render: (value) => {
        return (
          <div className="applicants-action">
            <span>
              <img
                className="cursor-pointer"
                src={allImage?.view}
                onClick={() => handleViewModal(value)}
                alt=""
              />
            </span>
            <span>
              <img
                className="cursor-pointer"
                src={allImage?.edit}
                alt="edit"
                onClick={() => handleRedirectEdit(value)}
              />
            </span>
          </div>
        );
      },
    },
    {
      key: 'first_name',
      sortable: true,
      header: 'First Name',
    },
    {
      key: 'last_name',
      sortable: true,
      header: 'Last Name',
    },
    {
      key: 'phone_number',
      sortable: false,
      header: 'Phone No.',
    },
    {
      key: 'email',
      sortable: true,
      header: 'Email',
    },
    {
      key: 'dob',
      sortable: true,
      header: 'Date of birth',
      render: (value) => {
        return (
          <span> {(value && moment(value).format(dateFormatTable)) || ''}</span>
        );
      },
    },
    {
      key: 'monthly_household_income',
      sortable: true,
      header: 'Income',
    },
    {
      key: 'employer_name',
      sortable: true,
      header: 'Employer Name',
    },
    {
      key: 'adult_occupants',
      sortable: true,
      header: '# of occupants',
    },
    {
      key: 'property_owner_notes',
      sortable: true,
      header: 'Notes',
    },
    {
      key: 'phone',
      sortable: true,
      header: 'Contact',
    },
  ];

  const items = [
    {
      id: 'first_name',
      value: 'first_name',
      label: 'First Name',
    },
    {
      id: 'last_name',
      value: 'last_name',
      label: 'Last Name',
    },
    {
      id: 'phone_number',
      value: 'phone_number',
      label: 'Phone No.',
    },
    {
      id: 'email',
      value: 'email',
      label: 'Email',
    },
    {
      id: 'dob',
      value: 'dob',
      label: 'Date of birth',
    },
    {
      id: 'monthly_household_income',
      value: 'monthly_household_income',
      label: 'Income',
    },
    {
      id: 'employer_name',
      value: 'employer_name',
      label: 'Employer Name',
    },
    {
      id: 'adult_occupants',
      value: 'adult_occupants',
      label: '# of occupants',
    },
    {
      id: 'property_owner_notes',
      value: 'property_owner_notes',
      label: 'Notes',
    },
    {
      id: 'phone',
      value: 'phone',
      label: 'Contact',
    },
  ];

  const handleSelectedMenu = (e) => {
    setSelectedMenu(e?.selectedItems || []);
  };

  const handleFilterMenu = () => {
    if (selectedMenu && selectedMenu?.length) {
      const filterData = [];
      headingData.map((list) => {
        if (list?.key === 'id') {
          filterData.push(list);
        }
        if (selectedMenu?.filter(({ value }) => value === list?.key)?.length) {
          filterData.push(list);
        }
      });
      return filterData;
    } else {
      return headingData;
    }
  };

  const filterHeader = handleFilterMenu();
  return (
    <>
      <div className="applicants-table-wrap">
        {toaster && (
          <Toaster
            title={error?.title}
            message={error?.message}
            type={error?.type}
            handleCloseToaster={handleCloseToaster}
          />
        )}
        <div className="applicants-listing-title">
          <h4>{details?.address}</h4>
          <ButtonControl onClick={handleRedirectProperty}>View</ButtonControl>
        </div>
        {/* <p>{details?.address}</p> */}
        <div className="applicants-listing-filter">
          {!refreshControl && (
            <MultiSelectDropdownControl
              ariaLabel="ComboBox"
              id="carbon-combobox-example"
              label="Select columns"
              items={items}
              name="utilities"
              onChange={handleSelectedMenu}
              initialSelectedItems={selectedMenu || []}
            />
          )}

          <ButtonControl>
            <img
              src={allImage?.downloadIcon}
              alt="download"
              onClick={handleDownloadInCSV}
            />
          </ButtonControl>
          <ButtonControl
            onClick={() => {
              setRefreshControl(true);
              setSelectedMenu([]);
            }}
          >
            Show all
          </ButtonControl>
        </div>
        <SearchFilter
          handleChange={(e) => handleChange({ first_name: e })}
          handleSearch={handleAddFilter}
          value={filterValue?.first_name || ''}
        />
        <DataTableControl
          filter={filter}
          columns={filterHeader}
          getData={getTableData}
          sortable
          showPagination
          noSelection
          loading={loading}
        />
        {applicantsViewModel && (
          <Modal
            className="applicants-view-modal"
            open={applicantsViewModel}
            onClose={() => applicantsViewModalClose()}
            title="Applicants details"
          >
            <ViewDetailModal {...{ selectedApplicant }} />
          </Modal>
        )}
      </div>
    </>
  );
}
