import { post, get, patch } from '../base';
const endPoint = 'landlord/property';

const getStaticProperties = async () => {
  const response = await get(`${endPoint}/static-data`);
  return response;
};

const getSingleProperty = async (id) => {
  const response = await get(`${endPoint}/${id}`);
  return response;
};

const getAllProperties = async () => {
  const response = await get(`${endPoint}`);
  return response;
};

const addProperty = async (body, header = null) => {
  const response = await post(`${endPoint}`, body, header);
  return response;
};

const updateProperty = async (id, body, header) => {
  const response = await patch(`${endPoint}/${id}`, body, header);
  return response;
};

const getPropertyPaymentUrl = async (id, redirect_urls) => {
  const response = await post(`${endPoint}/payment-url/${id}`, {
    redirect_urls,
  });
  return response;
};

const unListProperty = async (id) => {
  const response = await patch(`${endPoint}/request-un-list/${id}`);
  return response;
};

const getCountOfApplicantAndLead = async (id) => {
  const response = await get(`${endPoint}/lead-applicant-count/${id}`);
  return response;
};

export const propertiesAPI = {
  getStaticProperties,
  getSingleProperty,
  getAllProperties,
  addProperty,
  updateProperty,
  getPropertyPaymentUrl,
  unListProperty,
  getCountOfApplicantAndLead,
};
