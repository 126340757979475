import React, { useEffect, useState } from 'react';
import { allImage } from '../../Utils/allImageImport';

const StatisticsBox = ({ number, name, icon }) => {
  return (
    <div className="statistics-box">
      <div className="statistics-box-data">
        <img src={icon} alt={name} className="statistics-box-image" />
        <h3 className="statistics-box-number">{number}</h3>
      </div>
      <div>
        <p className="statistics-box-name">{name}</p>
      </div>
    </div>
  );
};

export default function Statistics({ propertiesCount, leadsCount, applicantsCount }) {
  return (
    <>
      <div className="statistics-component">
        <StatisticsBox number={propertiesCount} name="Properties" icon={allImage?.propertiesMenu} />
        <StatisticsBox number={leadsCount} name="Leads" icon={allImage?.leadsIcon} />
        <StatisticsBox number={applicantsCount} name="Applicants" icon={allImage?.applicantsIcon} />
      </div>
    </>
  );
}
