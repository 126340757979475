import { post, get } from '../base';
const endPoint = 'landlord/';

const login = async (body) => {
  const response = await post(`${endPoint}login`, body);
  return response;
};

const signup = async (body) => {
  const response = await post(`${endPoint}signup`, body);
  return response;
};

const sendCode = async () => {
  const response = await get(`${endPoint}signup/resend-code`);
  return response;
};

const emailVerify = async (body) => {
  const response = await post(`${endPoint}signup/email-verification`, body);
  return response;
};

const forgotPassword = async (body) => {
  const response = await post(`${endPoint}login/forgot-password`, body);
  return response;
};

const forgotPasswordVerifyCode = async (body) => {
  const response = await post(
    `${endPoint}login/forgot-password/code-verification`,
    body
  );
  return response;
};

const forgotPasswordSendCode = async (params) => {
  const response = await get(
    `${endPoint}login/forgot-password/send-code`,
    params
  );
  return response;
};

export const authAPI = {
  login,
  signup,
  sendCode,
  emailVerify,
  forgotPasswordSendCode,
  forgotPasswordVerifyCode,
  forgotPassword,
};
