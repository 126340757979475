import { allImage } from '../../Utils/allImageImport';
export const LandLordMenuList = [
  {
    title: 'Home',
    path: '/home',
    icon: allImage?.homeMenu,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Properties',
    path: '/properties',
    icon: allImage?.propertiesMenu,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Invoices',
    path: '/invoices',
    icon: allImage?.invoiceMenu,
    subMenu: false,
    menu: [],
  },
];
