import { Form, Stack, TextInput } from '@carbon/react';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { authAPI } from '../../API/Auth';
import MainLoader from '../../Components/Common/Loader/MainLoader';
import Toaster from '../../Components/Common/Toaster';
import { setUserLoginDetails } from '../../Redux/Actions/User';
import {
  isLoggedIn,
  setLoggedUser,
  setLoggedUserToken,
} from '../../Utils/common';
import { emailValidation } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';
import { allImage } from '../../Utils/allImageImport';

const VerifyEmailComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [verifyForm, setVerifyForm] = useState({
    email: '',
    code: '',
  });
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});
  const [verifyError, setVerifyError] = useState({});

  useEffect(() => {
    const info = isLoggedIn();
    setUserData(info);
  }, []);

  useEffect(() => {
    if (userData && userData?.email) {
      setVerifyForm({ ...verifyForm, email: userData?.email });
      handleSendCode(userData?.email);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setVerifyForm({
      ...verifyForm,
      [name]: (value && +value) || '',
    });
  };

  const handleManageError = () => {
    const { email, code } = verifyForm;
    let error = {};
    if (!code) {
      error.code = 'Code is required';
    } else if (code?.toString()?.length !== 6) {
      error.code = 'Code must be six character only';
    }
    if (!email) {
      error.email = 'Email is required';
    } else {
      const value = emailValidation(email);
      if (!value) {
        error.email = 'Please enter valid email';
      } else {
        delete error.email;
      }
    }
    return error;
  };

  const handleSubmit = async () => {
    let error = handleManageError();
    if (Object.keys(error).length) {
      setVerifyError(error);
      return;
    } else {
      setVerifyError({});
    }
    setLoading(true);
    const res = await authAPI?.emailVerify(verifyForm);
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      if (res?.data) {
        const userData = jwt_decode(res?.data?.token);
        setLoggedUser(userData);
        setLoggedUserToken(res?.data?.token);
        dispatch(setUserLoginDetails(userData));
        navigate('/home');
      }
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleSendCode = async (email) => {
    // const payload = {
    //   email: email ? email : verifyForm?.email,
    // };
    await authAPI?.sendCode();
  };

  return (
    <div className="login-form">
      <div className="logo">
        <img src={allImage?.logo} alt="logo" />
        <span>Curated Tenancy</span>
      </div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <div className="login-box">
        <h2 className="title">Verify Email</h2>
        <label>We emailed a 6 digit code to</label>
        <h5>
          <b>{userData?.email}</b>
        </h5>
        <Form>
          <Stack gap={5}>
            <TextInput
              labelText="Verification Token"
              placeholder="6 digit code"
              name="code"
              value={verifyForm?.code || ''}
              onChange={handleChange}
              required={true}
              id="firstName"
            />
            <label className="red-text">
              A verification code is required in order to create an account.
            </label>
            {verifyError?.code && (
              <label className="red-text">{verifyError?.code}</label>
            )}

            <div className="remember">
              <label>
                Didn’t receive the email?{' '}
                <label
                  className="cursor-pointer"
                  onClick={() => handleSendCode()}
                >
                  Resend code
                </label>
              </label>
            </div>
            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleSubmit}
                tabIndex={0}
                type="button"
              >
                Create Account
              </ButtonControl>
              <div id="signInDiv" className="sign-in-button"></div>
            </div>
            <div className="bottom-text">
              <p>
                Already have account? <Link to="/login">Log In</Link>
              </p>
            </div>
          </Stack>
        </Form>
      </div>
    </div>
  );
};

export default VerifyEmailComponent;
