import React, { useEffect, useState } from 'react';
import SiteHeading from '../../Common/SiteHeading';
import StatisticsComponent from '../../Home/statistics';
import ChartsComponent from '../../Home/charts';
import { leadAPI } from '../../../API/Lead';
import { applicantAPI } from '../../../API/Applicant';
import { propertiesAPI } from '../../../API/properties';

const Home = () => {
  const [leadsCount, setLeadsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [allProperties, setAllProperties] = useState([]);
  const [allApplicants, setAllApplicants] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      const allProperties = await propertiesAPI.getAllProperties();
      const allPropertiesIds = allProperties?.data.map((property) => property._id);

      const [leadsCount, applicantsCount] = await Promise.all([
        leadAPI.getAllLeadsCount({ propertyIds: allPropertiesIds || [] }),
        applicantAPI.getAllApplicants({ propertyIds: allPropertiesIds || [] }),
      ]);

      setLeadsCount(leadsCount?.data);  
      setPropertiesCount(allProperties?.data?.length);
      setApplicantsCount(applicantsCount?.data?.length);
      setAllApplicants(applicantsCount?.data);
      setAllProperties(allProperties?.data);
    };

    fetchAllData();
  }, []);

  return (
    <div>
      <div>Home</div>
      <SiteHeading Heading="Dashboard" />
      <StatisticsComponent {...{ propertiesCount, leadsCount, applicantsCount }} />
      <ChartsComponent {...{ allProperties, allApplicants }} />
    </div>
  );
};

export default Home;

