import moment from 'moment';

export const isLoggedIn = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  return userData || null;
};

export function setLoggedUser(data) {
  localStorage.setItem('userData', JSON.stringify(data));
}

export function setLoggedUserToken(data) {
  localStorage.setItem('token', data);
}

export function loggedOutUser() {
  localStorage.removeItem('userData');
  localStorage.removeItem('token');
}

export const userAuth = () => {
  const token = localStorage.getItem('token');
  const user = isLoggedIn();
  if (token && user) {
    return true;
  } else {
    return false;
  }
};

export const getToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return token;
  } else {
    return null;
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const dynamicId = () => {
  return (new Date().getTime() + (Math.random(3) + 1))?.toString();
};

export const ISOStringDate = (value) => {
  if (value && moment(value)?.isValid) {
    return new Date(value).toISOString();
  } else {
    return null;
  }
};

export const validateDate = (val) => {
  if (val) {
    let isValid = moment(val)?._isValid;
    if (isValid) {
      return val;
    } else {
      let splitValue = val.split('/');
      splitValue = `${splitValue[1]}/${splitValue[0]}/${splitValue[2]}`;
      return new Date(splitValue);
    }
  } else {
    return null;
  }
};
