import moment from 'moment';
import React, { useState, useMemo } from 'react';
import { SimpleBarChart, PieChart } from '@carbon/charts-react';
import '@carbon/charts-react/styles.css';

const monthMapping = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};


export default function Charts({ allProperties, allApplicants }) {
  const [objForListing, setObjForListing] = useState({
    'Draft': 0,
    'Submitted for Listing': 0,
    'Future': 0,
    'Listed': 0,
    'Un list Requested': 0,
    'Un listed': 0,
    'Ending': 0,
    'Expired': 0,
  });
  const [applicantsCountForEachDate, setApplicantsCountForEachDate] = useState({});

  useMemo(() => {
    const tempObjForListing = { ...objForListing };
    allProperties && allProperties?.forEach((property) => {
      tempObjForListing[property?.status] = Number(tempObjForListing[property?.status]) + 1;
    });

    setObjForListing(tempObjForListing);
  }, [allProperties]);

  useMemo(() => {
    const tempApplicantsCountForEachDate = { ...applicantsCountForEachDate };
    allApplicants && allApplicants?.forEach((applicant) => {
      const date = moment(applicant?.createdAt).format('DD/MM/YYYY');
      if (tempApplicantsCountForEachDate[date]) {
        tempApplicantsCountForEachDate[date] = tempApplicantsCountForEachDate[date] + 1;
      } else {
        tempApplicantsCountForEachDate[date] = 1;
      }
    });

    setApplicantsCountForEachDate(tempApplicantsCountForEachDate);
  }, [allApplicants]);

  const pieChart = () => {
    const pieChartData = [];
    for (const key in objForListing) {
      pieChartData.push({ group: key, value: objForListing[key] });
    }

    const pieChartOptions = {
      title: 'Properties Status',
      resizable: true,
      width: "97%",
      height: "400px",
    };

    return { pieChartData, pieChartOptions };
  }

  const barChart = () => {
    const barChartData = [];
    for (const key in applicantsCountForEachDate) {
      const dateArray = key.split('/');
      barChartData.push({ group: `${monthMapping[dateArray[1]]} ${dateArray[0]}`, value: applicantsCountForEachDate[key] });
    }

    const barChartOptions = {
      title: 'Applicants Count by Date',
      axes: {
        left: {
          mapsTo: 'value'
        },
        bottom: {
          mapsTo: 'group',
          scaleType: 'labels'
        }
      },
      width: "97%",
      height: "400px",
    };

    return { barChartData, barChartOptions };
  }

  const { pieChartData, pieChartOptions } = pieChart();
  const { barChartData, barChartOptions } = barChart();

  return (
    <>
      <div className="charts-component">
        <div style={{ width: '100%' }}>
          <PieChart data={pieChartData} options={pieChartOptions} />
        </div>
        <div style={{ width: '100%' }}>
          <SimpleBarChart data={barChartData} options={barChartOptions} />
        </div>
      </div>
    </>
  );
}
