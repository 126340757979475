import React from 'react';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import SiteHeading from '../../Common/SiteHeading';
import AddPropertyComponent from '../../Properties/addProperty';

export default function AddProperty() {
  return (
    <>
      <SiteHeading
        Heading="Add properties"
        menu={breadcrumb?.propertiesAddPage}
      />
      <AddPropertyComponent />
    </>
  );
}
