import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Content } from '@carbon/react';
import './App.scss';
import { Layout } from './Layout';
import PrivateRoute from './Routes/private';
import Home from './Components/Pages/Home';
import { isLoggedIn, userAuth } from './Utils/common';
import Properties from './Components/Pages/Properties';
import Invoice from './Components/Pages/Invoice';
import Login from './Components/Pages/Auth/login';
import Signup from './Components/Pages/Auth/signup';
import ForgotPassword from './Components/Pages/Auth/forgotPassword';
import { publicRoutes, removeLayoutStyleRoutes } from './Utils/constants';
import VerifyEmail from './Components/Pages/Auth/verifyEmail';
import AddProperty from './Components/Pages/Properties/addProperty';
import Applicants from './Components/Pages/Applicants';
import PropertyDetail from './Components/Pages/Properties/propertyDetail';
import EditProperty from './Components/Pages/Properties/editProperty';
import ApplicantsDetails from './Components/Pages/Applicants/applicantsDetails';

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!userAuth() && !publicRoutes?.includes(pathname)) {
      navigate('/login');
    }
    const userData = isLoggedIn();
    if (
      pathname !== '/email-verify' &&
      !publicRoutes?.includes(pathname) &&
      userAuth() &&
      userData &&
      userData?.email_verified === false
    ) {
      navigate('/email-verify');
    }
  }, [pathname, navigate]);

  return (
    <div className="App">
      {removeLayoutStyleRoutes?.includes(pathname) ? null : <Layout />}
      <Content
        className={
          removeLayoutStyleRoutes?.includes(pathname)
            ? 'cds--padding-0'
            : 'main-content'
        }
      >
        <Routes>
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/email-verify"
            element={
              <PrivateRoute>
                <VerifyEmail />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties"
            element={
              <PrivateRoute>
                <Properties />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/add-property"
            element={
              <PrivateRoute>
                <AddProperty />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/property-detail/applicants/:id"
            element={
              <PrivateRoute>
                <Applicants />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/property-detail/applicant/edit/:id"
            element={
              <PrivateRoute>
                <ApplicantsDetails />
              </PrivateRoute>
            }
          />
          <Route  
            exact
            path="/properties/property-detail/:id"
            element={
              <PrivateRoute>
                <PropertyDetail />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/edit-property/:id"
            element={
              <PrivateRoute>
                <EditProperty />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/invoices"
            element={
              <PrivateRoute>
                <Invoice />
              </PrivateRoute>
            }
          />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      </Content>
    </div>
  );
}

export default App;
