import React from 'react';
import InvoiceComponent from '../../Invoice';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import SiteHeading from '../../Common/SiteHeading';

export default function Invoice() {
  return (
    <>
      <SiteHeading Heading="Invoice" menu={breadcrumb?.invoicePage} />
      <InvoiceComponent />
    </>
  );
}
