import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { propertiesAPI } from '../../../API/properties';
import ButtonControl from '../../Common/Button';
import SiteHeading from '../../Common/SiteHeading';
import PropertiesComponent from '../../Properties';
import Toaster from '../../Common/Toaster';
import MainLoader from '../../Common/Loader/MainLoader';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';

const Properties = () => {
  const navigate = useNavigate();
  const [propertyLists, setPropertyLists] = useState([]);
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});

  useEffect(() => {
    getAllPropertyList();
  }, []);

  const getAllPropertyList = async () => {
    setLoading(true);
    const res = await propertiesAPI?.getAllProperties();
    if (res && res?.status === true) {
      setPropertyLists(res?.data || []);
      toaster && setToaster(false);
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
      setLoading(false);
    }
  };

  const handleAddProperty = () => {
    navigate('/properties/add-property');
  };

  return (
    <>
      {loading && <MainLoader />}
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      <SiteHeading Heading="Properties" menu={breadcrumb?.propertiesListPage}>
        <ButtonControl onClick={handleAddProperty}>
          Add property <span>+</span>
        </ButtonControl>
      </SiteHeading>
      <div className="property-wrap">
        <div className="cds--row">
          {propertyLists && propertyLists?.length > 0 ? (
            propertyLists.map((data, i) => {
              return (
                <div className="cds--col-xlg-4 cds--col-lg-6" key={i}>
                  <PropertiesComponent data={data} />
                </div>
              );
            })
          ) : (
            <b>You have not added any property yet.</b>
          )}
        </div>
      </div>
    </>
  );
};

export default Properties;
